<template>
  <div class="top-nav">
    <div class="top-box">
      <div class="fl">
        <b><span>{{loginStatus.webName}}</span>欢迎您！</b>
        <a href="javascript:;" class="name-text" v-html="loginStatus.userName"></a>
        <a href="/uc/loginout" class="exit-text" v-if="loginStatus.status">退出</a>
        <a href="javascript:;" class="exit-login-reg" @click="goLogin" v-if="!loginStatus.status">登录</a>
        <a href="avascript:;" class="exit-login-reg" v-if="!loginStatus.status">|</a>
        <a href="/uc/register" class="exit-login-reg" v-if="!loginStatus.status">注册</a>
      </div>
      <div class="fr">
        <a target="_blank" href="/header/coursetaste/coursetaste.html">免费体验</a>
        <a target="_blank" href="/v5/course/unionmy" class="course-ico">我的课程</a>
        <a target="_blank" href="/tiku/exam/chapter" class="tiku-ico">免费题库</a>
        <a target="_blank" href="/v5/uc/unionuser" class="per-ico">个人中心</a>
        <!-- <a
          href="javascript:;"
          class="map-ico"
          @mouseenter="daohangenter"
          @mouseleave="daohangleave"
          v-if="username"
          >网站地图<b class="top-sjx"></b
        ></a>
        <transition name="slide-fade">
          <div
            class="layer-border"
            v-show="daohangshow"
            @mouseenter="daohangdiventer"
            @mouseleave="daohangdivleave"
          >
            <div class="icon-up-arrow"><i></i></div>
            <div class="n-unity" v-html="smalldaohang"></div>
            <div class="more_course clearfix" v-html="bigdaohang"></div>
          </div>
        </transition> -->
      </div>
    </div>
  </div>
</template>

<script>
// import { GetUserInfo, GetConfig } from "../../../lib/request"
export default {
  name: "Nav",
  props: {
    loginStatus: {
      type: Object,
      default: () => {}
    }
  },
  // data() {
  //   return {
  //     //网站名称
  //     unionname: "",
  //     //用户名称
  //     username: "",
  //     //导航小
  //     smalldaohang: "",
  //     //导航大
  //     bigdaohang: "",
  //     //是否显示导航
  //     daohangshow: false,
  //     //鼠标是否在导航div上
  //     isindaohangdiv: false,
  //   }
  // },
  // mounted() {
  //   this.load()
  // },
  methods: {
  //   //页面加载
  //   load() {
  //     GetUserInfo({ host: location.host }, (res) => {
  //       this.username = res
  //     })
  //     var domin = window.location.host
  //     if (sessionStorage.getItem("config" + domin)) {
  //       this.setwebsite(JSON.parse(sessionStorage.getItem("config" + domin)))
  //     } else {
  //       GetConfig({ host: location.host }, (res) => {
  //         sessionStorage.setItem("config" + domin, JSON.stringify(res))
  //         this.setwebsite(res)
  //       })
  //     }
  //   },
    //填充站点信息
    // setwebsite(config) {
    //   this.unionname = config.WebName
    //   this.smalldaohang = config.SmallHtml
    //   this.bigdaohang = config.BigHtml
    //   this.uurl = config.DozDomain
    //   if (config.UnionLogo == null || config.UnionLogo.length == 0) {
    //     this.isimg = false
    //   } else {
    //     this.imgsrc = config.UnionLogo
    //   }
    //   if (config.Kefu) {
    //     if (
    //       config.Kefu.UnionQQHtml != null &&
    //       config.Kefu.UnionQQHtml.length > 0
    //     ) {
    //       this.qqhtml +=
    //         '<tr><td class="service_title_td"><span class="service_name_span">在线咨询：</span></td></tr>'
    //       this.qqhtml += config.Kefu.UnionQQHtml
    //     }
    //     if (
    //       config.Kefu.UniontelHtml != null &&
    //       config.Kefu.UniontelHtml.length > 0
    //     ) {
    //       this.telhtml +=
    //         '<tr><td class="service_title_td"><span class="service_name_span">电话咨询：</span></td></tr>'
    //       this.telhtml += config.Kefu.UniontelHtml
    //       this.telhtml += '<tr><td style="height: 5px"></td></tr>'
    //     }
    //     if (
    //       ((config.Kefu.UnionQQHtml == null ||
    //         config.Kefu.UnionQQHtml.length == 0) &&
    //         config.Kefu.UniontelHtml == null) ||
    //       config.Kefu.UniontelHtml.length == 0
    //     ) {
    //       this.qqservicevis = false
    //     } else {
    //       this.qqservicevis = true
    //     }
    //   }
    // },
    // //鼠标移入导航a标签
    // daohangenter() {
    //   this.daohangshow = true
    // },
    // //鼠标移出导航a标签
    // daohangleave() {
    //   setTimeout(() => {
    //     if (!this.isindaohangdiv) {
    //       this.daohangshow = false
    //     }
    //   }, 100)
    // },
    // //鼠标移入导航div
    // daohangdiventer() {
    //   this.isindaohangdiv = true
    // },
    // //鼠标移出导航div
    // daohangdivleave() {
    //   this.isindaohangdiv = false
    //   this.daohangshow = false
    // },
    goLogin() {
      window.location.href = window.location.origin + "/uc/login?redirecturl=" + window.location.href
    }
  }
}
</script>

<style lang="scss">
.top-nav {
  width: 100%;
  margin: 0 auto;
  background: #f9f9f9;
  border-bottom: 1px solid #e7e7e7;
  box-shadow: 0 2px 1px 1px #f9f9f9;
  font-size: 12px;
  a {
    text-decoration: none;
  }
  .top-box {
    width: 1200px;
    margin: 0 auto;
    height: 16px;
    padding: 12px 0;
  }

  .top-box .fr,
  .top-box .fl {
    position: relative;
    z-index: 30;
  }
  .top-box a,
  .top-box a:link,
  .top-box a:visited {
    display: inline-block;
    height: 14px;
    line-height: 12px;
    color: #333;
    padding: 0 0 0 18px;
    margin-right: 20px;
    cursor: pointer;
    position: relative;
  }
  .top-box a.course-ico {
    background: url(//img.233.com/wx/img/union/courseandexam/hd.png) 0 0 no-repeat;
  }
  .top-box a.shop-ico {
    background: url(//img.233.com/wx/img/union/courseandexam/20180508/hd.png) 0 -230px
      no-repeat;
  }
  .top-box a.tiku-ico {
    background: url(//img.233.com/wx/img/union/courseandexam/hd.png) 0 -22px no-repeat;
  }
  .top-box a.per-ico {
    background: url(//img.233.com/wx/img/union/courseandexam/hd.png) 0 -47px no-repeat;
  }
  .top-box a.map-ico {
    background: url(//img.233.com/wx/img/union/courseandexam/hd.png) 0 -78px no-repeat;
  }
  .top-box a b.top-sjx {
    background: url(//img.233.com/wx/img/union/courseandexam/hd.png) 0 -101px no-repeat;
    width: 6px;
    height: 4px;
    position: absolute;
    right: -10px;
    top: 5px;
    font: normal 0/0 "arial";
  }
  .top-box a i {
    padding: 0 5px;
    background: #f06000;
    color: #fff;
    font-size: 12px;
    border-radius: 100px;
    display: block;
    position: absolute;
    line-height: 17px;
    left: 65px;
    top: -10px;
    font-style: normal;
  }
  .top-box b {
    display: inline;
    font-weight: normal;
    line-height: 12px;
  }
  .top-box a.name-text {
    color: #e43100 !important;
    padding: 0;
    margin: 0;
    font-weight: bold;
  }
  .top-box a.exit-text {
    padding: 0 0 0 14px;
    margin: 0;
  }
  .top-box a.exit-login-reg {
    padding: 0 0 0 6px;
    margin: 0;
  }
  .top-box a:hover {
    color: #e43100;
  }
  .top-box a:hover.course-ico {
    background: url(//img.233.com/wx/img/union/courseandexam/hd.png) 0 -120px no-repeat;
  }
  .top-box a:hover.tiku-ico {
    background: url(//img.233.com/wx/img/union/courseandexam/hd.png) 0 -142px no-repeat;
  }
  .top-box a:hover.per-ico {
    background: url(//img.233.com/wx/img/union/courseandexam/hd.png) 0 -167px no-repeat;
  }

  .top_logo_span {
    display: block;
    font-size: 33px;
    line-height: 80px;
    max-width: 330px;
    overflow: hidden;
    white-space: nowrap;
    float: left;
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .clearfix:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: ".";
  }
  .clearfix {
    zoom: 1;
  }
  .layer-border {
    position: absolute;
    right: 0px;
    top: 28px;
    font-family: "microsoft yahei";
    border: 1px solid #d5d5d5;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    background: white;
  }
  .icon-up-arrow {
    width: 20px;
    height: 0;
    position: relative;
    font-size: 0;
    line-height: 0;
    float: right;
  }
  .icon-up-arrow i {
    position: absolute;
    top: -7px;
    width: 20px;
    right: 10%;
    height: 11px;
    overflow: hidden;
    background: url(//img.233.com/wx/img/union/courseandexam/hd.png) no-repeat 0 -192px;
    clear: both;
  }

  .layer-border .n-unity {
    overflow: hidden;
    background: #fff;
    width: 140px;
  }
  .layer-border .n-unity span {
    padding-left: 10px;
    max-width: 155px;
    height: 22px;
    overflow: hidden;
  }
  .layer-border .n-unity a {
    width: 135px;
    height: 22px;
    line-height: 22px;
    padding: 7px 15px 9px;
    overflow: hidden;
    display: block;
    color: #444;
    font-size: 14px;
    margin-right: 0;
    width: 110px;
    &:hover {
      background-color: #f6f6f6;
      color: #f30;
    }
  }
  .layer-border .n-unity span {
    float: left;
    padding-left: 12px;
    cursor: pointer;
  }
  .more_course {
    background: #fff;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 12px 12px 12px 20px;
    font-size: 13px;
  }
  .more_course dl {
    float: left;
    margin-bottom: 10px;
    width: 625px;
  }
  .more_course dl dt {
    float: left;
    font-weight: 700;
    display: block;
    margin-right: 15px;
    margin-top: 1px;
    line-height: 20px;
    width: 70px;
  }
  .more_course dl dd {
    border-bottom: 1px solid #f2f2f2;
    width: 300px;
    padding-bottom: 2px;
    float: right;
    width: 540px;
  }
  .more_course dl dd a {
    display: block;
    margin-right: 3px;
    text-align: left;
    padding: 0 5px 8px !important;
    line-height: 20px !important;
    margin-bottom: 6px;
  }
  .more_course dl dd a:hover {
    background: #e43100;
    color: #fff;
    border-radius: 3px;
  }
  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active 用于 2.1.8 以下版本 */ {
    transform: translateX(10px);
    opacity: 0;
  }
}
</style>